import React, { useCallback, useRef } from 'react'
import { Modal, Form, Input, Button } from 'antd';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;

  button {
    float: right;
  }
`;

const encode = data => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&');
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
};

export default function ContactForm() {
  const formRef = useRef();

  const handleSuccess = useCallback(function () {
    Modal.success({
      title: 'Thank you for reaching out. We will get back to you as soon as possible.'
    });
    if (formRef.current) {
      formRef.current.resetFields();
    }
  });

  const handleSubmit = useCallback(function (values) {
    fetch('/?no-cache=1', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...values }),
    })
      .then(handleSuccess)
      .catch(error => alert(error))
  });

  return (
    <Wrapper>
      <Form
        {...formItemLayout}
        onFinish={handleSubmit}
        data-netlify="true"
        data-netlify-honeypot="bot"
        name="contact"
        ref={formRef}
      >
        <input type="hidden" name="form-name" value="contact" />
        <p hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot" />
          </label>
        </p>
        
        <Form.Item
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input your full name',
            },
          ]}
        >
          <Input name="name" placeholder="Name" />
        </Form.Item>

        <Form.Item
          name="email"
          rules={[
            {
              type: 'email',
              message: 'The input is not a valid email',
            },
            {
              required: true,
              message: 'Please input your email',
            },
          ]}
        >
          <Input name="email" placeholder="Email" />
        </Form.Item>

        <Form.Item name="useCase">
          <Input name="useCase" placeholder="Why are you using WZRD? What's your background?" />
        </Form.Item>

        <Form.Item
          name="message"
          rules={[
            {
              required: true,
              message: 'Please input a message',
            },
          ]}
        >
          <Input.TextArea name="message" placeholder="Message" rows="6" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" size="large">
            Send
          </Button>
        </Form.Item>
      </Form>
    </Wrapper>
  );
}
