import React from 'react'
import { Collapse } from 'antd';
import styled from 'styled-components';
import { lightgrey } from '../colors';

const StyledCollapse = styled(Collapse)`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background: none;

  .ant-collapse-item {
    border-radius: calc(0.6em + 12px) !important;
    margin-bottom: 20px;
    background: ${lightgrey[0]};
    border: 0;

    .ant-collapse-content-box {
      padding-bottom: 5px;

      p {
        font-size: 95%;
        color: ${lightgrey[6]};
        line-height: 1.6;
        margin-top: 0;
      }
    }
  }
`;

const FAQ = ({ faqs, ...props }) => {
  return (
    <StyledCollapse bordered={false} {...props}>
      {faqs.map((faq) => (
        <Collapse.Panel key={faq.id} header={faq.question}>
          <p
            dangerouslySetInnerHTML={{ __html: faq.answer.childMarkdownRemark.html }}
          />
        </Collapse.Panel>
      ))} 
    </StyledCollapse>
  )
}

export default FAQ
