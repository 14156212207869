import React from 'react'
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ContactForm from '../components/ContactForm'
import SEO from '../components/SEO'
import FAQ from '../components/FAQ';
import { brand } from '../colors';

const DiscordBanner = styled.div`
  max-width: 500px;
  margin: 0 auto 58px auto;
  display: flex;
  justify-content: center;
  background-color: #7289DA; // Discord primary color
  padding: 15px 0 7px 0;
  border-radius: 4px;
  color: white;

  p {
    padding: 0;
    margin: 0;
    margin-left: 15px;
    text-align: left;
  }
  
  a {
    color: ${brand[2]};
  }

  img {
    margin-top: -3px;
    opacity: 0.3 !important;
  }

  .gatsby-image-wrapper > img {
    opacity: 0 !important;
  }
`;

const Contact = ({ data }) => {
  const faqs = data.allContentfulFaqList.edges[0].node.entries

  return (
    <Layout>
      <SEO title="Get in touch" description="Frequently asked questions and contact form" />
      <Container>
        <PageTitle>Common Questions</PageTitle>
        <FAQ faqs={faqs} style={{ marginBottom: 60 }} />

        <PageTitle>Get in touch</PageTitle>

        {/* <DiscordBanner>
          <StaticImage
            src="../assets/images/discord.png"
            alt="discord-logo"
            layout="fixed"
            width={64}
            height={64}
            placeholder="none"
          />

          <p>You can join us on Discord <a href="https://discord.gg/7HbzeFhbg3">here</a>,<br />
          or use the contact form below.</p>
        </DiscordBanner> */}

        <ContactForm />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulFaqList {
      edges {
        node {
          entries {
            id
            answer {
              childMarkdownRemark {
                html
              }
            }
            question
          }
        }
      }
    }
  }
`;

export default Contact
